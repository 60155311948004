<template>
  <div class="service_list_box">
    <div class="service_item_list">
      <div

        class="row service_list_box"
        :style="`height: ${search_message.length > 0 ? '80% !important' : 'auto !important'}`"
      >
        <div
          class="col-12 mb-2"
        >
          <div class="sorting_view_main_box">
            <div class="title_view">
              <h4 class="search_result_text">
                Favorite({{ business_profiles.length }})
              </h4>
              <!-- v-b-tooltip.hover.top="'sort alphabetically'" -->
              <div

                class="sort_item_box_view"
                @click="sortData"
              >
                <img
                  v-if="sort_type === 'DESC'"
                  :src="SortDown"
                  alt="sort"
                >
                <img
                  v-if="sort_type === 'ASC'"
                  v-b-tooltip.hover.top="'sort alphabetically'"
                  :src="SortUp"
                  alt="sort"
                >
              </div>
            </div>

            <div class="search-input-view">
              <b-form-input
                id="h-search-keyword"
                v-model="searchTerm"
                type="text"
                placeholder="search favorite"
                class="form-control"
                style="min-width: 450px;"
              />
            </div>
          </div>
        <!-- Sorting view -->

          <!-- <img
              v-if="sort_type === 'ASC'"
              v-b-tooltip.hover.top="'sort alphabetically'"
              :src="SortUp"
              alt="sort"
            > -->
          <!-- sorting view end -->
        </div>

        <div
          class="col-12"
        >
          <div
            class="row"
          >
            <div
              v-for="(item, index) in business_profiles"

              :key="index"
              class="col-12 col-md-6 col-lg-4 mb-2"
              @click="showDetails(item)"
            >
              <div class="service_card_box">
                <div class="service_image_box">
                  <div
                    class="service_image"
                    :style="getImage(item.cover)"
                  >
                    <div
                      v-if="user_type === 'member' || userData.is_vendor === true"
                      class="tag_box"
                    >
                      <div
                        v-b-tooltip.hover.top="item.favorite ? 'Remove from favourite' : 'Add to favourite'"
                        class="book-mark-box"
                        :class="item.favorite ? 'like' : ''"
                        @click.stop="addToFavourite(item)"
                      >
                        <img
                          v-if="item.favorite"
                          :src="FavoriteIcon"
                          alt="sort"
                        >
                        <img
                          v-else
                          :src="FavoriteIcon2"
                          alt="sort"
                        >
                      </div>

                      <div
                        v-b-tooltip.hover.top="'Recommend Service'"
                        class="book-mark-box"
                        @click.stop="showRecomModal(item.id)"
                      >
                        <feather-icon icon="CornerUpRightIcon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card_details">
                  <div class="tag-view">

                    <span> {{ item.category }}</span>
                    <span>{{ item.state }}</span>
                  </div>

                  <h3 class="list_heading">
                    {{ item.name | setTruncate(34, "...") }}
                  </h3>

                  <div class="review_view">
                    <!-- <span>{{ item.rating > 0 ? item.rating : '' }}</span>
                    <feather-icon
                      icon="StarIcon"
                      size="15"
                    /> -->
                    <span></span>
                    <span>({{ item.total_reviews > 0 ? item.total_reviews : 'No' }} {{ item.total_reviews > 1 ? 'reviews' : 'review' }})</span>
                    <b-form-rating
                      v-model="item.rating"
                      variant="warning"
                      inline
                      no-border
                      readonly
                      class="raiting_box"
                    />
                    <span v-if="item.rating > 0">{{ item.rating }} out 5</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div
      v-if="business_profiles.length >= 12 || profile_param.page > 1"
      class="pagination_box"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="profile_params.per_page"
        size="lg"
        align="center"
        @change="onPageChange"
      />
    </div>
    <b-modal
      id="recom-modal"
      title="Recommend Member"
      hide-footer
    >
      <b-card-text>
        <RecomendForm
          :id="business_id"
          :hide="hide"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCardText, VBTooltip, BModal, VBModal, BPagination, BFormInput,BFormRating
} from 'bootstrap-vue'
import notFoundImage from '@/assets/images/icons/empty.svg'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-duplicates
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'
// import SortRatingDown from '../../../assets/images/icons/generic-rating-sorting-asc.png'
// import SortRatingUp from '../../../assets/images/icons/generic-rating-sorting-desc.png'
import FavoriteIcon from '../../../assets/images/icons/heart-deep.png'
import FavoriteIcon2 from '../../../assets/images/icons/heart-plain.png'
import StarIcon from '../../../assets/images/icons/star.png'
// import { latLngBounds, Icon } from 'leaflet'
import serviceImage from '../../../assets/images/service/service1.jpg'
import RecomendForm from './RecomendForm.vue'

export default {
  components: {
    BModal,
    BCardText,
    BPagination,
    RecomendForm,
    BFormInput,
    BFormRating,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      SortUp,
      SortDown,
      StarIcon,
      FavoriteIcon,
      FavoriteIcon2,
      notFoundImage,
      state_selected: [],
      currentPage: 1,
      county_selected: [],
      selected_sub_category: [],
      business_id: null,
      show_county: false,
      selected: [],
      is_favourite_items: [],
      serviceImage,
      zoom: 13,
      perPage: 12,
      recommend_page: 1,
      sort_type: 'DESC',
      sort_rating_type: 'DESC',
      searchTerm: '',
    }
  },
  computed: {
    business_profiles() {
      const items = this.$store.state.businessProfile.favorites
      return items.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    search_message: {
      get() {
        return this.$store.state.businessProfile.search_message
      },
      set(value) {
        return value
      },
    },
    search_result: {
      get() {
        return this.$store.state.businessProfile.search_result_text
      },
    },
    enable_sort() {
      return this.$store.state.businessProfile.enable_sort
    },
    profile_params() {
      return this.$store.state.businessProfile.favorite_param
    },
    totalRows() {
      return this.$store.state.businessProfile.total_favorite_count
    },
    profile_param: {
      get() {
        return this.$store.state.businessProfile.favorite_param
      },
      set(value) {
        return value
      },
    },
    center: {
      get() {
        return this.$store.state.businessProfile.center
      },
      set(value) {
        return value
      },
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    county_options() {
      return this.counties.map(item => ({
        text: item.name, value: item.id,
      }))
    },
    state_options() {
      return this.states.map(item => ({ text: `${item.name}, ${item.abbreviation}`, value: item.id }))
    },
    categories() {
      return this.$store.state.category.categories_profile
    },
    category_options() {
      return this.categories.map(item => ({ text: item.name, value: item.id }))
    },
    sub_categories() {
      return this.$store.state.subCategory.sub_categories
    },
    sub_category_options() {
      return this.sub_categories.map(item => ({ text: item.name, value: item.id }))
    },
    userData() {
      return this.$store.state.user.profile
    },
  },
  mounted() {
    this.getBusinessProfile()
    this.getState()
    this.user_type = localStorage.getItem('gts_user_type')
  },

  methods: {
    getBusinessProfile() {
      this.$store.dispatch('businessProfile/getProfileFavorite', this.profile_param)
    },
    onPageChange(page) {
      this.profile_param.page = page
      this.getBusinessProfile()
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
      }
      return image
    },
    setTextTruncate(text, length, clamp) {
      // eslint-disable-next-line no-param-reassign
      clamp = clamp || '...'
      const node = document.createElement('div')
      node.innerHTML = text
      const content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },
    sortData() {
      if (this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.$store.dispatch('businessProfile/sortFavorite', { data: this.business_profiles, sort_type: this.sort_type })
    },
    sortAlphabetically() {
      this.business_profiles.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    getCategory() {
      return this.$store.dispatch('category/getCategories')
    },
    showRecomModal(id) {
      this.business_id = id
      this.$bvModal.show('recom-modal')
    },
    hide() {
      this.$bvModal.hide('recom-modal')
      this.business_id = null
    },
    showDetails(item) {
      localStorage.setItem('gts_profile_id', item.id)
      this.$store.dispatch('businessProfile/getSingleBusinessProfile', item.id)
      const slug = item.name.replaceAll(' ', '-').toLocaleLowerCase()
      this.$router.push(`/services/${slug}`)
    },
    addToFavourite(item) {
      const data = {
        member_id: this.userData.id,
        business_profile_id: item.id,
        favorite: !item.favorite,
      }

      this.$store.dispatch('businessProfile/addToFavourite', { item: data, param: this.profile_params })
    },
  },
}
</script>

  <style lang="scss" scoped>

  </style>
